
.sideBar {
    height: 100%;
    padding-top: 10px;
    border-right: 1px solid lightgrey;
    position: fixed;
    justify-content: space-between;
    background-color: #ffffff;
}

.data {
    width: 100%;
    margin-left: 160px;
}

.toolbar {
    display: none;
    right: 5%;
    position: fixed;
    max-width: 90%;
    justify-content: flex-end;
    padding: 10px 20px 7px 0;
    z-index: 11;
}

.toggleTabs span {
    font-size: 0.75rem;
    font-weight: 500;
    text-transform: uppercase;
    color: #3e4450;
    padding: 0 3px;
}

.tabs {
    color: blue !important;
}

.tabs button {
    min-width: 125px !important;
    padding: 5px 12px !important;
    min-height: 35px !important;
}

.tabsIcon {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: baseline;
    font-size: 11px;
    cursor: pointer;
}

.adminTab {
    display: none !important;
    width: 100%;
    margin-top: 70px !important;
}

.addItem {
    display: none;
    height: 40px;
    width: 40px;
    z-index: 11;
    background: #ffffff;
    border-radius: 50%;
}

.card {
    padding: 10px;
    margin: 15px;
    display: flex;
    flex-direction: column;

    border-radius: 10px !important;
    position: relative;
    transition: all 0.4s ease !important;
    background-color: #ffffff !important;
    border: 1px solid rgba(211, 211, 211, 0.6);
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.37) !important;
}


.card:hover {
    border: 1px solid rgba(0, 0, 0, 0.35);

}

.item {
    cursor: default;
    text-align: center;
    padding: 7px 5px;
    border-radius: 20px;
    font-size: 16px;
    word-wrap: break-word;
    max-width: 70%;
}

.itemInStock {
    width: 33%;
    align-self: center;
    margin-top: 20px !important;
}


.itemStatus {
    padding: 4px 8px;
    color: white;
    border-radius: 20px;
    text-align: center;
    font-size: 0.9rem;
    width: 55px;
}

.listTitle {
    width: 33%;
    margin: 10px 0;
    text-align: center;
    color: white;
}

.table {

    width: 100%;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    border-radius: 0 !important;
    margin: 0 0 110px 0;
    border-top: 1px solid rgba(0, 0, 0, 0.12) !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
    border-right: none !important;
    border-left: none !important;
}

.itemOdd {
    background-color: #fff;
    width: 100%;
    display: flex;
}

.itemEven {
    background-color: #dddddd !important;
    width: 100%;
    display: flex;
}

.sold {
    color: red;
    font-weight: bold;
}

.addDevice {
    font-size: 40px;
    cursor: pointer;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.72);
    border-radius: 50%;
    transition: all 0.5s ease 0s
}

.addDevice:hover {
    box-shadow: none;
}

.imageLabel {
    padding: 5px 10px;
    border: 1px solid #36299e;
    border-radius: 20px;
    cursor: pointer;
    transition: all .3s linear;
}

.imageLabel:hover {
    background: #3c2db3;
    color: white;
}


/*      Popup        */

.popup {
    position: fixed;
    z-index: 11;
    min-height: calc(100vh + 55px);
    width: 100vw;
    top: -55px;
    left: 0;
    background-color: rgba(60, 60, 60, 0.8) !important;
    opacity: 0;
    transition: all 0.4s ease 0s;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    flex-wrap: nowrap !important;
}

.form {
    background-color: #fff;
    border-radius: 15px;
    padding: 15px;
    /*transform: perspective(600px) translate(0px, -100%) rotateX(45deg);*/
    transition: all 0.8s ease 0s !important;
    width: 375px;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid lightgray;
    padding-bottom: 5px;
    cursor: default;
}
.step {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.step p {
    color: #464646;
    font-size: 13px;
}

.stageOne, .stageTwo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 10px 0 20px 0;
    cursor: default;
    color: white;
}

.stageOne div {
    height: 4px;
    width: 70px;
    background-color: #68718c;
    border-radius: 50px;
}
.stageTwo div {
    height: 4px;
    width: 70px;
    background-color: #089f73;
    border-radius: 50px;
}

.stageOne span, .stageTwo span {
    padding: 5px;
    border: 2px solid #68718c;
    border-radius: 50%;
    margin: 0 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);

}

.formTitle {
    font-size: 23px;
    margin: 5px;
}

.closePopup {
    cursor: pointer;
    text-shadow: 2px 2px 4px #0000006e;
    text-decoration: none;
    color: #ce2323;
    font-size: 25px;
    margin-right: 10px;
    height: 30px;
}

.closePopup:hover {
    text-shadow: none;
}

.open {
    visibility: visible;
    opacity: 1;
    
}


.input {
    justify-content: space-around;
    width: 100%;
    margin-bottom: 15px;
}

.checkDigit {
    width: 65%;
}

.digit {
    width: 25%;
    margin-left: 20px !important;
}

.amount {
    margin-bottom: 15px;
    width: 50%;
}


/*     mobile     */


.mobileLog {
    display: none;

}

.log {
    width: 100%;
    height: 400px;
    justify-content: space-around;
    align-items: center;
}

.details {
    padding-top: 5px;
    border-top: 1px solid lightgray;
    margin-top: 5px;
    display: flex;
    flex-direction: column-reverse;

}

.showDetails {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: gray;
    font-size: 13px;
    cursor: pointer;
    width: 100%;
}

.credentials {
    display: none;
    flex-direction: column;
}


/* search */
.searchBox {
    display: none;
    background: rgba(255, 255, 255, 0);
    height: 40px;
    border-radius: 50px;
    align-items: center;
    margin-right: 15px;
}

.searchBox:hover > .searchInput {
    width: 240px;
    padding: 0 6px;
    border: 1px solid gray;
}

.searchBox:hover > .searchButton {
    background: white;
    color: #000000;

}

.searchButton {
    cursor: pointer;
    color: white;
    float: right;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.4s;
}

.searchInput {
    border: none;
    background: white;
    outline: none;
    float: left;
    padding: 0;
    color: black;
    font-size: 16px;
    transition: 0.4s;
    line-height: 38px;
    width: 0;
    border-radius: 20px;

}


.dwnld:hover {
    animation: move .5s ease-in-out;
}

.dwnld:active {
    animation: move .5s ease-in-out;
}

@keyframes move {
    0% {
        transform: translateY(0px);;
    }
    50% {
        transform: translateY(5px);
    }
    100% {
        transform: translateY(0px);;
    }
}


.line {
    display: flex;
    justify-content: space-between;
}



/*  mobile menu */

.menu {
    display: none;
    position: absolute;
    top: -380%;
    left: 5%;
    border-radius: 5px;
    border: 1px solid lightgray;
}

.menu li {
    list-style-type: none;
    background-color: #fff;
    padding: 25px;
    text-align: center;
}


.menu li:nth-child(2) {
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
}

.mobileListTabs {
    cursor: pointer;
    margin-left: 20px;
    padding: 2px 5px;
    border-radius: 50%;
}

.mobileListTabs:active span{
    color: rgba(255, 255, 255, 0.24);
}

.mobileListTabs:hover .menu {
    display: block;
}

.mobileListTabs span {
    color: white;
}

.formTabs {
    transform: rotate(270deg) translateY(7px);
    width: 30px;
    height: 35px;
    display: flex;
    flex-direction: row;
    margin-bottom: 50px;
}
.formTabs p {
    background-color: #fff;
    padding: 2px 15px;
    margin: 0px 10px;
    border-radius: 10px;
    height: 100%;
    cursor: pointer;
}

.submenu {
    position: absolute;
    display: none;
    border-radius: 10px;
    padding: 5px 0;
    border: 1px solid lightgray;
    background-color: #fff;
    left: 0;
    top: 110%;
    z-index: 10;
}

.submenu li {
    padding: 5px 10px;
    background-color: #fff;
    list-style-type: none;

}

.repairStatus, .submenu li p {
    color: white;
    cursor: pointer;
    padding: 0 5px;
    border-radius: 5px;
    text-align: center;
    max-width: 100px;
}

.submenu li:nth-child(1) p {
    background-color: rgb(231,86,86);
    color: white;
}
.submenu li:nth-child(2) p {
    background-color: rgb(138, 198, 136);
    color: white;
}
.submenu li:nth-child(3) p {
    background-color: rgb(107, 183, 245);
    color: white;
}



























@media (max-width: 960px) {

    .listTabs {
        display: none;
    }

    .toggleTabs span {
        color: white;
    }
    .form {
        padding: 5px 15px;
        /* width: 365px;
        margin-right: 10px; */
    }

    .data {
        margin: 0;
        padding-bottom: 40px;
    }

    .toolbar {
        display: flex;
        border: none;
    }

    .mobileLog {
        display: flex !important;
    }

    .log {
        display: none !important;
    }

    .table {
        margin-top: 0;
    }

    .sideBar {
        height: auto;
        width: 100%;
        background: #36299e;
        position: fixed;
        padding: 10px 0;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        flex-direction: row;
        z-index: 11;
    }

    .addItem {
        display: block;
        position: fixed;
        bottom: 80px;
        right: 15px;
    }

    .tabsIcon {
        color: white;
        font-size: 10px;
    }

    .searchBox {
        display: flex;
        z-index: 11;
        position: fixed;
        top: auto;
        bottom: 80px;
        right: 70px;
    }
    .adminTab {
        display: block !important;
        width: auto;
        color: white !important;
        font-size: 13px;
        margin-top: 0 !important;
    }
    .tabs {
        display: none !important;
    }
    
}

@media screen and (max-width: 620px) {
    .searchBox:hover > .searchInput {
        width: 150px;
        padding: 0 6px;
    }

    .header {
        border: none;
        margin-bottom: 0;
    }


    .item {
        padding: 5px;
        font-size: 15px;
    }
}