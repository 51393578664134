
.logo {
    display: flex;
    align-items: center;
}


.activeLink {
    background: rgba(255, 255, 255, 0.21);
    border-radius: 10px;
    position: relative;
    border: none !important;

}

.link {
    padding: 10px 15px 9px 15px;
    border-bottom: 1px solid transparent;
    text-align: center;
    color: white;
    transition: all 0.5s ease 0s;
    font-size: 13px;
    align-self: center;
}

.btn {
    padding: 10px;
    color: white;
    font-size: 13px;
    border-radius: 20px;
    cursor: pointer;
    transition: color .1s linear;
    background-color: rgba(173, 216, 230, 0.301);
}

.btn:hover {
    background-color: rgba(173, 177, 230, 0.301);
}

.userPhoto {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    margin: 5px;
    cursor: pointer;
}

.userPhoto:hover {
    border: 3px solid rgba(172, 172, 172, 0.3);
    margin: 2px;
}


.signOut {
    margin-left: 10px;
    cursor: pointer;
    color: lightgray !important;
    transition: color .1s linear;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.signOut span {
    padding: 0 2px;
}

.signOut:hover {
    color: white !important;
}

.link:hover {
    border-bottom: 1px solid white;
}

.navSearch {
    margin: 0 5px;
    height: 23px;
    padding: 5px 10px;
    outline: none;
    border: 1px solid rgb(177, 177, 177);
    background: #2313a2;
    border-radius: 20px;
    color: white;
}

.navSearch::placeholder {
    color: rgb(202, 199, 199);
}

.title {
    cursor: default;
    color: white;
    font-size: 2.125rem;
}


.subMenu {
    position: absolute;
    height: 300px;
    width: 150px;
    background-color: #fff;
    color: black;
    top: 70px;
    right: 50px;
    border: 1px solid lightgray;
    padding: 10px;
    border-radius: 10px;
    z-index: 1221;
}


/*      footer          */

.footer {

    margin-top: 200px;
    background-color: #36299e;
}

.footerInner {
    width: 100%;
    flex-wrap: wrap;
    display: flex !important;
    flex-direction: row;
    justify-content: space-around;
    padding: 20px 0;
    min-height: 70px;
    color: white;
}

.linkList {
    display: flex;
    flex-direction: column;
}
.linkList h3 {
    margin-bottom: 10px;
    font-size: 15px;
}
.linkList a {
    padding: 0 0 5px 10px;
    font-size: 13px;
    color: #bfbfbf;
}
.linkList a:hover {
    text-decoration: underline !important;
}


/*      NOTIFICATIONS        */


.notify {
    position: fixed;
    padding: 15px 0;
    top: 64px;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #fff;
    color: rgb(0, 0, 0);
    font-size: 18px;
    border-bottom: 1px solid lightgray;
    box-shadow: inset 0 -3px 8px rgba(157, 157, 157, 0.07);

    transition: transform 0.3s linear;
    transform: translateY(0);
    z-index: 1099;
}

.closeNotify {
    transform: translateY(-64px);
}


@media (max-width: 900px) {
    .navSearch {
        display: none;
    }

    .btn {
        display: none;
    }

}

@media screen and (max-width: 620px) {
    .logo img {
        height: 40px !important;
    }

    .title {
        font-size: 25px !important;
    }

    .link {
        padding: 5px 5px;
        font-size: 10px;
        height: auto;
    }

    .activeLink {
        padding: 5px 5px;

    }

    .notify {
        width: 97.2% !important;
        padding: 15px 5px;
        font-size: 20px;
        top: 55px;
    }

    .upgrade {
        font-size: 14px;
    }

    .footerInner {
        justify-content: flex-start;
        flex-direction: column;
    }
    .linkList {
        margin-bottom: 20px;
    }
    .signOut p {
        display: none;
    }
}