*{
  margin: 0;
  padding: 0;
}
td, th {
  cursor: default;
}
body {
  background-color: #fff;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

a{
  text-decoration: none !important;
  cursor: pointer;
}





















@media (max-width: 900px) {

  .searchBox {
    top: auto;
    bottom: 80px;
    right: 70px;
  }

}




