@keyframes text {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    border-radius: 20px;
    padding: 40px 15px;

}

.free {
    background: linear-gradient(25deg, #3253cc 0%, #d98efb 100%);
}

.free:before {
    content: 'Free';
    position: absolute;
    font-size: 150px;
    font-weight: bold;
    color: #17166817;
    transform: rotate(45deg);
    top: 20%;
    animation: text 3s ease-in-out infinite;
}

.monthly {
    background: linear-gradient(160deg, #3253cc 0%, #d98efb 100%);
}

.monthly:before {
    content: 'Monthly';
    position: absolute;
    font-size: 100px;
    font-weight: bold;
    color: #683c1621;
    transform: rotate(45deg);
    top: 30%;
    animation: text 3s ease-in-out infinite;

}

.halfYear {
    background: linear-gradient(160deg, #3253cc 0%, #d98efb 100%);
}

.halfYear:before {
    content: 'Half year';
    position: absolute;
    font-size: 80px;
    font-weight: bold;
    color: #683c1621;
    transform: rotate(45deg);
    top: 30%;
    left: 10%;
    animation: text 3s ease-in-out infinite;

}

.yearly {
    background: linear-gradient(145deg, #d98efb 0%, #3253cc 100%);
}

.yearly:before {
    content: 'Yearly';
    position: absolute;
    font-size: 100px;
    font-weight: bold;
    color: #68161625;
    transform: rotate(45deg);
    top: 30%;
    animation: text 3s ease-in-out infinite;

}

.cardItem {
    width: 30%;
    height: 100%;
    object-fit: cover;
    position: relative;
    z-index: 0;

    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, rgba(0, 0, 0, 0.56));

    transform-origin: center;
    transform: perspective(800px) rotateY(25deg) !important;
    transition: 0.5s !important;
}

.container {

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

}

.container:hover .cardItem {
    opacity: 0.3 !important;
}

.container .cardItem:hover {
    transform: perspective(800px) rotateY(0deg) !important;
    opacity: 1 !important;
}

.price {
    font-weight: bold !important;
    margin-bottom: 50px !important;
}

.cardText {
    width: 90%;
    padding: 10px;
    display: flex;
    align-items: center;
    position: relative;

    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: 0.0075em;
}

.cardText span {
    color: green;
    margin-right: 10px;
}

.cardText:before {
    content: '';
    background: url("../../assets/arrow_up.png");
    top: -20px;
    left: 44%;
    height: 20px;
    width: 50px;
    position: absolute;
}

.btn {
    margin-top: 20px !important;
    font-size: 1.4rem !important;
    font-weight: 600 !important;
    color: white !important;
    letter-spacing: .1rem !important;
    min-width: 100px;
    transition: all 0.2s linear;
}

.btn:hover {
    transform: scale(1.1);
}

.bckBtn {
    margin-top: 150px !important;
    margin-bottom: 40px !important;
}

.title {
    margin: 80px 0 !important;
    cursor: default;

}

.cardTitle {
    font-size: 25px;
}


@media (max-width: 900px) {
    .free:before {
        font-size: 100px;
    }

    .monthly:before {
        font-size: 60px;
        transform: rotate(45deg);
    }

    .yearly:before {
        font-size: 80px;
        transform: rotate(45deg);
    }
}


@media screen and (max-width: 620px) {
    .cardItem {
        -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, transparent);
        transform: perspective(0) rotateY(0) !important;

    }

    .container {
        flex-wrap: wrap;

    }

    .title {
        margin: 0 0 15px 0 !important;
    }

    .cardText {
        font-size: 1.05rem;
    }

    .card {
        padding: 10px 5px;
        margin: 10px 0;
    }

    .price {
        margin-bottom: 20px !important;
        font-size: 40px !important;
    }

    .free:before {
        font-size: 100px;
    }

    .monthly:before {
        font-size: 60px;
        transform: rotate(45deg);
    }

    .halfYear:before {
        font-size: 60px;
        transform: rotate(45deg);
    }

    .yearly:before {
        font-size: 80px;
        transform: rotate(45deg);
    }

    .container:hover .cardItem {
        opacity: 1 !important;
    }

    .cardTitle {
        font-size: 14px;
    }

    .cardText {

        font-size: 12px;
    }

    .bckBtn {
        margin-top: 60px !important;
    }

    .cardText:before {
        left: 44%;
    }

    .btn {
        font-size: 1rem !important;
    }
}