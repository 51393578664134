
.container {
    margin-top: 100px;
    margin-bottom: 70px;
}
.container p {
    padding: 10px 0;
    line-height: 24px;
}
.container h5 {
    font-size: 20px;
    margin: 30px;
}
.container ul {
    margin-left: 50px;
}
.container ul li {
    margin: 8px 0;
    line-height: 24px;
}

.contact, .contactPage {
    margin-top: 70px;
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;

}

.contact a, .contactPage a {
    padding: 0 10px;
    color: #1e3f79;

    text-decoration: underline !important;
}

.contactPage h4 {
    margin: auto;
    padding-bottom: 30px;
    font-size: 30px;

}
.contactPage p {
    font-size: 20px;
}