.main {
    margin-top: 100px;
    cursor: default;
    display: flex;
    flex-direction: column;
}

.line {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.linePart {
    min-width: 340px;
    margin-bottom: 40px;
    box-shadow: rgba(0, 0, 0, 0.18) 0px 1px 2px, rgba(0, 0, 0, 0.11) 0px 0px 0px 1px;
    border-radius: 20px;
    padding: 10px 20px;
}

.linePart h3{
    margin-bottom: 20px;
}


.title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.email, .subscription {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 450px;
}

.email, .subscription p {
    font-size: 18px;
    cursor: default;
}

.subscription {
    margin-top: 20px;
}

.cancelSub {
    margin-top: 80px;
}

.exportCSV {
    margin: 0 0 50px 0;
    padding: 25px 0;
    height: 84px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.exportCSV p {
    font-size: 18px;
}
.exportCSV .dwnld {
    background-color: transparent;
    border-radius: 50%;
    border: 2px solid black;
    padding: 3px 5px;
    margin-left: 15px;
    cursor: pointer;
}














/*          ALERT           */



.alert {
    color: #e59b00;
    border-bottom: 1px solid #e3b341;
    border-top: 1px solid #e3b341;
    padding: 20px 5px;
    font-size: 14px;
    background-image: linear-gradient(#bb80091a, #bb80091a);
}

.info {
    padding: 10px 5px;
    font-size: 15px;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji;
}

.button {
    margin: 10px 0 !important;
    font-size: 11px !important;
    font-weight: 500;
    line-height: 20px;
    padding: 5px 0 !important;
    border: 1px solid rgba(255, 20, 87, 0.49) !important;
}



@media screen and (max-width: 620px) {
    .buttons button {
        font-size: 0.5rem !important;
    }

    .email, .subscription p {
        font-size: 17px;
        cursor: default;
    }
    .linePart {
        min-width: 305px;
    }
}