.login {
    max-width: 400px !important;
    min-height: 250px;
    display: flex;
    justify-content: center;
    border-radius: 35px;
    background: #fcfcfc;
    box-shadow:  13px 13px 18px #c5c5c598,
    -13px -13px 18px #ebebeb;

}

.form {
    display: flex;
    flex-direction: column;
    width: 70%;
}

.logo {
    display: flex;
    align-items: center;
    margin-bottom: 50px;
}

.logo p {
    font-size: 35px;
    font-weight: bold;
    cursor: default;

}
.logo img {
    margin-right: 10px;
    height: 70px;
}

.title {
    font-size: 20px;
    margin: 20px 0;
    text-align: center;
    cursor: default;

}
.title span {
    color: #36299e;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 25px;
}

.title p {
    color: #5f5f5f;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 20px;
    letter-spacing: 2px;
    margin-top: 10px;
}

.btn {
    color: white !important;
    background-color: #3f51b5 !important;
    width: 33%;
    margin: 20px auto !important;
    
}


@media screen and (max-width: 620px) {
    .logo {
        margin-bottom: 30px;
    }
}



