.headerImage {
    background-image: url('../../assets/header.jpg');
    background-size: cover;
    background-position: center;
    min-height: 700px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.56);
}

.title {
    padding-top: 100px;
    color: #fff;
    font-weight: bold !important;
    cursor: default;
}
.aboutTitle {
    padding-top: 100px;
    margin: auto;
    font-family: sans-serif;
    font-size: 5em;
    color: transparent;
    text-transform: uppercase;
    -webkit-text-stroke: 0.04em black;
    cursor: default;

}
.subtitle {
    padding-top: 100px;
    color: #fff;
    font-weight: 300 !important;
    cursor: default;

}
.button {
    margin-top: 100px;
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

.video {
    background-color: #8a8a8a;
    margin-top: 100px;
    width: 100%;
    height: 500px;
}

.about {
    margin-top: 100px;
    padding-bottom: 30px;
}

.text {
    width: 45%;
    display: flex;
    align-items: center;
    padding: 30px;
    background: linear-gradient(
            45deg
            , #204fb0 0%, #63b9e8 100%);
    -webkit-filter: drop-shadow(0px 20px 10px rgba(79, 123, 188, 0.4));
    border-radius: 3rem;
}

.icon {
    display: flex;
    align-items: center;
    height: 70px;
    padding: 25px;
    border-radius:50%;
    background-color: #fff;
    box-shadow:0 0 60px 10px rgba(51, 93, 245, 0.63), inset 0 -10px 30px rgba(14, 28, 72, 0.36);
}

.aboutText {
    width: 70%;
    font-size: 18px;
    color: #ffffff;
    font-weight: lighter;
    cursor: default;

}
.aboutImage {
    height: 70px;
    width: 70px;
    opacity:0.8;
}









@media (max-width: 900px) {
    .text {
        width: 80%;
        margin-top: 20px;
    }
    .about {
        overflow: hidden;
    }
}

@media screen and (max-width: 620px) {
    .aboutTitle {
        font-size: 45px;
    }
    .title {
        font-size: 60px !important;
    }
    .video {
        font-size: 40px !important;
    }
    .text {
        flex-wrap: wrap;
        width: auto;
        margin-top: 20px;
    }
    .about {
        overflow: hidden;
    }
    .aboutText {
        margin: 5px 0;
        width: auto;
    }
    .icon {
        margin: auto !important;
        height: 55px;
        width: 55px;
        margin-bottom: 10px;
    }
    .aboutImage {
        height: 55px;
        width: 55px;
    }
}