
.info {
    padding: 0 !important;
    height: 35px !important;
    width: 35px !important;
    border-radius: 50% !important;
    min-width: 0 !important;

}

.info span {
    cursor: pointer;
    color: #6b778c;
    padding: 5px;
}

.userInfo {
    padding: 0 24px;
    margin-top: 24px;

}

.backBtn {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    width: 170px;
}

.backBtn span {
    font-size: 30px;
    color: #36299e;
}

.backBtn:hover span {
    text-shadow: 0 0 5px rgba(87, 74, 191, 0.26);
}

.backBtn p {
    margin-left: 10px;
    color: rgba(0, 0, 0, 0.66);
}


.main {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 100px;
}

.details, .devices {
    min-width: 45%;
    margin-top: 24px;
    box-shadow: rgba(0, 0, 0, 0.18) 0px 1px 2px, rgba(0, 0, 0, 0.11) 0px 0px 0px 1px;
    border-radius: 20px;
    cursor: default;
}

.devices {
    min-width: 40%;
    max-height: 250px;
    margin-left: 120px;
}

.details h3, .devices h3 {
    padding: 16px;
    color: #172b4d;
    font-weight: normal;
    font-size: 1.0938rem;
    line-height: 1.6;
}

.detailsItem {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 10px;
    border-top: 1px solid lightgray;
}

.detailsItem span {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.57;
    color: rgb(23, 43, 77);
    width: 45%;
}

.detailsItem p, .formtype {
    border-radius: 10px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    color: #6b778c;
    padding-right: 20px;
}









.blockButton {
    padding: 0 !important;
}

.blockButton p {
    color: white ;
    width: auto !important;
    margin: 0 5px;
    font-size: 13px;
}

.blockButton span {
    font-weight: bold;
    font-size: 18px;
    color: white ;
    width: auto !important;
    margin: 0 5px;
}







.mobileListTabs {
    display: none;
    margin-left: 20px;
    padding: 2px 5px;
    border-radius: 50%;
}

.mobileListTabs:focus span{
    color: rgba(255, 255, 255, 0.24);
}


.mobileListTabs span {
    color: white;
}


.menu {
    position: absolute;
    top: -375%;
    left: 5%;
    border-radius: 5px;
    border: 1px solid lightgray;
}

.menu li {
    list-style-type: none;
    background-color: #fff;
    padding: 25px;
    text-align: center;
}


.menu li:nth-child(2) {
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
}
.adminSettings {
    margin: 80px 0 0 80px;
}

.form {
    margin-top: 100px;
    max-width: 375px;
}

.input {
    margin-top: 20px;
}

.button {
    margin-top: 40px;
    margin-bottom: 40px;
    margin-left: 35%;
}














@media (max-width: 960px) {

    .mobileListTabs {
        display: block;
    }

    .adminSettings {
        margin: 80px 0 0 20px;
    }

    .userInfo {
        padding: 0 10px;
    }

    .details, .devices {
        min-width: 100% ;
    }
    .devices {
        margin-left: 0;
    }
    .detailsItem span {
        width: 50%;
    }
    .form {
        margin-top: 50px;
    }
}